<template>
  <div>
    <div class="banner municipal-banner">
      <div class="banner-item">{{item}}</div>
    </div>
    <div class="container">
      <div class="service">
        <div class="service-title">活动智能垃圾四分类回收站。</div>
        <div class="service-p service-p-sp">
          <p>
            活动式智能垃圾四分类亭是一款致力于解决中国垃圾分类新政后原始基建缺失的产品。
          </p>
          <p>
            它通过智能化物联 网技术结合现代轻材料建筑特点，为城市居民提供高端、
            便利、时尚的垃圾投放场景，让民众能更好的参与分
          </p>
          <p>类投递，也为政府智能管控，高效落实提供了最大的保障。</p>
        </div>
        <div class="service-ma">
          <div class="row-inline-block service-content">
            <div class="service-content-title">
              <p>活动式智能垃圾</p>
              <p>四分类厢房 A</p>
            </div>
            <ul class="service-content-cc">
              <li>1.一体式建造落地</li>
              <li>2.灵活移动设计，无需土建成型</li>
              <li>3.轻钢结构结实耐用，可拆可移动</li>
              <li>4.抗风等级 14 抗震等级 8 级</li>
            </ul>
            <div class="service-content-type">型号：XSS-LJT01</div>
          </div>
          <div class="row-inline-block service-img"><img src="../../assets/img/municipal/municipal-1.png" alt="" /></div>
        </div>
        <div class="service-ma">
          <div class="row-inline-block service-content">
            <div class="service-content-title">
              <p>活动式智能垃圾</p>
              <p>四分类厢房 B</p>
            </div>
            <ul class="service-content-cc">
              <li>1.一体式建造落地</li>
              <li>2.灵活移动设计，无需土建成型</li>
              <li>3.轻钢结构结实耐用，可拆可移动</li>
              <li>4.抗风等级 14 抗震等级 8 级</li>
            </ul>
            <div class="service-content-type">型号：XSS-LJT02</div>
          </div>
          <div class="row-inline-block service-img"><img src="../../assets/img/municipal/municipal-2.png" alt="" /></div>
        </div>
      </div>

      <div class="service">
        <div class="service-title">智能垃圾分类亭。</div>
        <div class="service-p service-p-sp">
          <p>智能垃圾分类亭由结实、耐用的环保型材料一体建造，可抗风防震。</p>
          <p>
            分类亭直接落地于社区及街道的垃圾
            投放点位，占地不大外观时尚美观，无需土建又能满足
            垃圾分类的点位要求。 同时分类厅搭
          </p>
          <p>
            配智能设备、广告宣传屏、LED 屏及洗
            手池为一体，为民众提供便利洁净的垃圾分类投放场景。
          </p>
        </div>
        <div class="service-ma">
          <div class="row-inline-block service-content">
            <div class="service-content-title">
              <p>智能垃圾四分类亭 A</p>
            </div>
            <ul class="service-content-cc">
              <li>1.一体式建造落地</li>
              <li>2.灵活移动设计，无需土建成型</li>
              <li>3.轻钢结构木质表皮结实耐用，可拆可移动</li>
              <li>4.抗风等级 13 抗震等级 7 级</li>
              <li>5.铝合金管材 复合实木板材 树脂辅材</li>
            </ul>
            <div class="service-content-type">型号：XJD-FLT01</div>
          </div>
          <div class="row-inline-block service-img"><img src="../../assets/img/municipal/municipal-3.png" alt="" /></div>
        </div>
        <div class="service-ma">
          <div class="row-inline-block service-content">
            <div class="service-content-title">
              <p>智能垃圾四分类亭 B</p>
            </div>
            <ul class="service-content-cc">
              <li>1.一体式建造落地</li>
              <li>2.灵活移动设计，无需土建成型</li>
              <li>3.轻钢结构木质表皮结实耐用，可拆可移动</li>
              <li>4.抗风等级 13 抗震等级 7 级</li>
              <li>5.轻钢龙骨、铝板外立面及 ABS 高分子材料</li>
            </ul>
            <div class="service-content-type">型号：XJD-FLT02</div>
          </div>
          <div class="row-inline-block service-img"><img src="../../assets/img/municipal/municipal-4.png" alt="" /></div>
        </div>
        <div class="service-ma">
          <div class="row-inline-block service-content">
            <div class="service-content-title">
              <p>智能垃圾四分类亭 C</p>
            </div>
            <ul class="service-content-cc">
              <li>1.一体式建造落地</li>
              <li>2.灵活移动设计，无需土建成型</li>
              <li>3.轻钢结构木质表皮结实耐用，可拆可移动</li>
              <li>4.抗风等级 13 抗震等级 7 级</li>
              <li>5.铝合金管材 复合实木板材 树脂辅材</li>
            </ul>
            <div class="service-content-type">型号：XJD-FLT03</div>
          </div>
          <div class="row-inline-block service-img"><img src="../../assets/img/municipal/municipal-5.png" alt="" /></div>
        </div>
      </div>

      <div class="service">
        <div class="service-title">小松鼠垃圾箱房改造项目。</div>
        <div class="service-p service-p-sp">
          <p>
            精灵小松鼠为市政及街道提供美观实用的垃圾厢房改造，迎合垃圾分类是一种新时尚的需求，并且采取多种智
          </p>
          <p>能设备结合，使得功能方面能符合居民定时定点投放的需求。</p>
        </div>
        <div class="service-ma">
          <div class="row-inline-block">
            <div class="service-content" style="height: 664px">
              <div class="service-content-title">
                <p>#垃圾箱房改造方案</p>
                <p class="service-content-title-sp">DUMPSTER RENOVATION PLAN</p>
              </div>
            </div>
            <div class="service-content-img1"><img src="../../assets/img/municipal/municipal-6.png" alt="" /><div class="service-img-info">#2改造效果实景图</div></div>
          </div>
          <div class="row-inline-block service-img" style="height: 892px;">
            <div class="service-content-img2"><img src="../../assets/img/municipal/municipal-7.png" alt="" /><div class="service-img-info">#1垃圾箱房改造方案</div></div>
            <div class="service-content-img3"><img src="../../assets/img/municipal/municipal-8.png" alt="" /><div class="service-img-info">#1改造效果实景图</div></div>
          </div>
        </div>
      </div>
      <div style="height: 164px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item:'市政项目'
    };
  },

  mounted: function () {},
  methods: {},
};
</script>

<style>
.municipal-banner{
    background-image: url('../../assets/img/municipal/municipal-banner.png');
}
.service-content {
  width: 400px;
  height: 439px;
  background: linear-gradient(90deg, #0285ff 0%, #02aafe 100%);
  padding: 30px 20px;
  color: #ffffff;
  text-align: left;
  position: relative;
}
.service-content-title {
  font-size: 20px;
}
.service-content-title-sp {
  font-size: 16px;
  font-family: MicrosoftYaHeiLight;
  margin-top: 20px;
  font-weight: 300;
}
.service-content-cc {
  margin-top: 45px;
  line-height: 36px;
}
.service-content-type {
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.service-ma {
  margin-bottom: 20px;
}
.service-p-sp {
  margin-bottom: 80px;
}
.service-content-img1 {
  position: relative;
  height: 228px;
}
.service-content-img2 {
  position: relative;
  height: 436px;
}
.service-content-img3 {
  position: relative;
  height: 456px;
}
.service-img-info{
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  padding: 14px 24px;
  color:#fff;
}
</style>